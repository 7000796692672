import axios from 'axios'
import Vue from 'vue';

export default {
  namespaced: true,
  state: {
    userId: 0,
    users: [],
    matches: [],
    leagues: []
  },
  mutations: {
    SET_USERID(state, id) {
      state.userId = id
    },
    SET_STATS(state, stats) {
      console.log('SET_STATS', stats)
      Vue.set(state, 'users', stats.users)
      Vue.set(state, 'matches', stats.matches)
      Vue.set(state, 'leagues', stats.leagues)
    }
  },
  actions: {
    async fetchBettingStats({ commit, rootState }, params) {
      try {
        // console.log("params: " + JSON.stringify(new_params));
        const response = await axios.get('https://api.ip008.com/odds/stats.php', {
          params: params
        })
        
        commit('SET_STATS', response.data)
        // console.log('state after setting', this.state)
      } catch (error) {
        console.error('Error fetching stats:', error)
      }
    }
  },
  getters: {
    getMatchById: (state) => (id) => {
      return state.matches.find(match => match.match_id === id)
    }
  }
}
