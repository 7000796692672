<template>
  <div class="check-row">
    <!-- <h3 class="check-row__title">{{ title }}</h3> -->
    <div class="check-row__left-title" :style="{'background-color': titleColor}">{{ title }}</div>
    <div class="check-row__right-content">
      <a-row :gutter="0">
        <a-col 
          v-for="item in odds" 
          :key="item.name"
          :span="24 / colCount"
        >
          <check-grid 
            :item="item"
            :selectedOddsItems="selectedOddsItems"
            @selection-change="handleSelectionChange"
          />
        </a-col>
      </a-row>
    </div>
  </div>
</template>

<script>
import { Row, Col } from 'ant-design-vue';
import CheckGrid from './CheckGrid.vue';

export default {
  name: 'CheckRow',
  components: {
    CheckGrid,
    'a-row': Row,
    'a-col': Col
  },
  props: {
    colCount: {
      type: Number,
      default: 3
    },
    odds: {
      type: Array,
      required: true
    },
    title: {
      type: String,
      default: ''
    },
    titleColor: {
      type: String,
      default: '#000'
    },
    selectedOddsItems: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  methods: {
    handleSelectionChange(event) {
      this.$emit('row-selection-change', event);
    }
  }
}
</script>

<style scoped>
.check-row {
  margin-bottom: 1px;
  display: flex;
  align-items: stretch; /* 修改为stretch，让子元素等高 */
  /* justify-content: space-between; */
}
.check-row__title {
  margin-top: 10px;
  font-weight: bold;
}
.check-row__left-title {
  width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* background-color: lightblue; */
  border: 1px solid #e8e8e8;
  /* margin-top: 10px; */
}
.check-row__right-content {
  width: 100%;
  text-align: left;
  /* align-items: center; */
  /* background-color: lightgreen; */
}
</style>