<template>
  <a-layout-header class="header">
    <div v-if="false" class="logo">足球竞猜</div>
    <a-menu
      v-model:selectedKeys="selectedKeys"
      theme="light"
      mode="horizontal"
      :style="{ lineHeight: '64px' }"
    >
      <a-menu-item key="Home">
        <router-link to="/home">我要下注</router-link>
      </a-menu-item>
      <a-menu-item key="MyOdds">
        <router-link to="/MyOdds">下注历史</router-link>
      </a-menu-item>
      <a-menu-item key="Stats">
        <router-link to="/Stats">统计</router-link>
      </a-menu-item>
      <a-menu-item key="Settings">
        <router-link to="/settings">设置</router-link>
      </a-menu-item>
    </a-menu>
    <div v-if="false" class="user-info">
      <a-dropdown>
        <a class="ant-dropdown-link" @click.prevent>
          用户名 <a-icon type="down" />
        </a>
        <template #overlay>
          <a-menu>
            <a-menu-item>
              <a href="javascript:;" @click="logout">退出登录</a>
            </a-menu-item>
          </a-menu>
        </template>
      </a-dropdown>
    </div>
  </a-layout-header>
</template>

<script>
export default {
  data() {
    return {
      selectedKeys: [this.$route.name]
    };
  },
  watch: {
    $route: {
      immediate: true,
      handler(newRoute) {
        this.selectedKeys = [newRoute.name || newRoute.matched[0]?.name];
      }
    }
  },
  methods: {
    logout() {
      localStorage.removeItem('isLoggedIn');
      this.$router.push('/');
    },
  }
};
</script>

<style scoped>
.header {
  display: flex;
  align-items: center;
  background: #fff;
  padding: 0 24px;
  position: relative;
  border-bottom: 1px solid #f0f0f0;
}

.logo {
  width: 120px;
  height: 31px;
  margin: 16px 24px 16px 0;
  float: left;
  color: #1890ff;
  font-size: 20px;
  line-height: 31px;
  font-weight: bold;
}

.user-info {
  margin-left: auto;
  color: rgba(0, 0, 0, 0.85);
}

.ant-dropdown-link {
  color: rgba(0, 0, 0, 0.85);
}

.ant-menu-item-selected {
  color: #1890ff !important;
}

.ant-menu-item-selected::after {
  border-bottom: 2px solid #1890ff !important;
}

@media (max-width: 768px) {
  .header {
    padding: 0 16px;
  }
  
  .logo {
    margin: 16px 0;
    font-size: 18px;
  }
  
  .ant-menu-item {
    padding: 0 8px;
  }
  
  .user-info {
    margin-left: auto;
  }
}
</style>
