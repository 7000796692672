<template>
  <div class="home-container">
    <Header /> <!-- 引入顶部导航菜单组件 -->

    <div class="league-selection">
      <a-checkbox
      v-model:checked="leagueSelectAll"
      :indeterminate="leagueSelectionIndeterminate"
      @change="onCheckAllChange"
      >
        全选
      </a-checkbox>
      <div class="league-list">
        <a-row justify="space-between">
          <a-col 
            v-for="league in leagues" 
            :key="league.leagueId"
          >
            <a-checkbox :value="league.leagueId" :checked="leagueSelected.includes(league.leagueId)" @change="onLeagueSelecttionChange(league.leagueId)">{{ league.leagueName }}</a-checkbox>
          </a-col>
        </a-row>
      </div>
    </div>
    <div class="match-selection">
      <a-select
        v-model:value="selectedMatchId"
        @change="handleMatchChange"
        placeholder="选择比赛"
        style="width: 100%; max-width: 400px"
      >
        <a-select-option value="">选择比赛</a-select-option>
        <a-select-option 
          v-for="match in matches" 
          :key="match.match_id" 
          :value="match.match_id"
        >
          <template v-if="betting_matches.includes(match.match_id)">
            ✓ {{ match.homeTeam }} vs {{ match.awayTeam }} ({{ match.matchTime }})
          </template>
          <template v-else>
            &nbsp;&nbsp;&nbsp;&nbsp;{{ match.homeTeam }} vs {{ match.awayTeam }} ({{ match.matchTime }})
          </template>
        </a-select-option>
      </a-select>

      <a-select
        v-if="false"
        v-model:value="selectedOddsTime"
        @change="handleOddsChange"
        style="width: 200px"
      >
        <a-select-option 
          v-for="(table, index) in currentMatchOddsTables" 
          :key="index" 
          :value="table.publishTime"
        >
          {{ table.publishTime }}
        </a-select-option>
      </a-select>
    </div>
    <div v-if="currentOdds">
      <div class="recommendation-buttons">
        <a-space wrap>
          <a-button @click="applyRecommendation1">推荐方案1</a-button>
          <a-button @click="applyRecommendation2">推荐方案2</a-button>
        </a-space>
      </div>
    </div>

    <div class="odds-list" v-if="currentOdds">
      <div v-for="(value, key) in currentOdds.odds" :key="key" class="odds-item">
        <label>
          <input type="checkbox" 
                 v-model="selectedOddsItems" 
                 :value="{ type: key, odds: value }">
          {{ key }} ({{ value }})
        </label>
      </div>
    </div>

    <div v-if="currentOdds">
      <div>
        <!-- {{ selectedOddsItems }} -->
        <!-- {{ tableDataSet }} -->
        <check-table 
          :data="tableDataSet"
          :selectedOddsItems="selectedOddsItems"
          @table-selection-change="handleTableSelectionChange"
        />
      </div>
    </div>

    <div class="betting-table-section" v-if="selectedOddsItems.length">
      <h3>投注表 ({{ currentMatch.homeTeam }} vs {{ currentMatch.awayTeam }})</h3>
      <div class="action-area">
        <a-input-group compact>
          <a-input-number v-model:value="amountPerOdd" step="10" style="width: 200px" />
          <a-button type="primary" @click="applyMoneyPerOdd">投注</a-button>
        </a-input-group>
      </div>
   
      <table class="betting-table" v-if="selectedOddsItems.length">
        <thead>
          <tr>
            <th>比分</th>
            <th>赔率</th>
            <th>投注</th>
            <th>收益</th>
            <th>收益减成本</th>
            <th>收益率</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in selectedOddsItems" :key="index">
            <td>{{ item.title }}</td>
            <td>{{ item.value }}</td>
            <td>
              <!-- <input type="number" v-model.number="bettingAmounts[index]" @input="calculateResults"> -->
              <a-input-number v-model:number="bettingAmounts[index]" step="10"  @input="calculateResults" />
            </td>
            <td>{{ calculateReturn(item.value, bettingAmounts[index]) }}</td>
            <td>{{ calculateNetReturn(item.value, bettingAmounts[index]) }}</td>
            <td>{{ calculateROI(item.value, bettingAmounts[index]) }}%</td>
          </tr>
          <tr class="total-row">
            <td colspan="2">总计</td>
            <td>{{ totalBetting }}</td>
            <td colspan="3"></td>
          </tr>
        </tbody>
      </table>

      <div class="action-area">
        <a-select
          v-if="userId == 1"
          v-model:value="selectedUserId"
          placeholder="选择用户"
          style="width: 40%; max-width: 100px"
        >
          <a-select-option 
            v-for="user in users" 
            :key="user.user_id" 
            :value="user.user_id"
          >
          {{ user.info }}
          </a-select-option>
        </a-select>
        <button class="save-button" @click="saveBettingData" v-if="selectedOddsItems.length">
          保存
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import axios from 'axios'
import CheckboxTable from '../components/CheckboxTable.vue'
import CheckTable from '../components/CheckTable.vue';
import Header from '../components/Header.vue';

export default {
  name: 'Home',
  meta: {
    title: '足彩助手 - 投注'
  },
  components: {
    Header,
    CheckTable,
    CheckboxTable
  },
  data() {
    return {
      leagueSelectAll: false,
      leagueSelectionIndeterminate: false,
      leagueSelected: [],
      selectedMatchId: '',
      selectedOddsTime: '',
      selectedOddsItems: [],
      selectedUserId: "1",
      bettingAmounts: [],
      amountPerOdd: 100,
      oddNames: {'had':'胜负', 'crsh':'主队胜', 'crsd':'主队平', 'crsa':'主队负', 'ttg':'总进球数', 'hafu':'半全场胜平负'}
    }
  },
  computed: {
    userId() {
      return localStorage.getItem('userId')
    },
    allMatches() {
      return this.$store.state.matches.list
    },
    matches() {
      return this.$store.state.matches.list.filter(match => this.leagueSelected.includes(match.leagueId));
    },
    betting_matches() {
      return this.$store.state.matches.betting_matches
    },
    leagues() {
      return this.$store.state.matches.leagues
    },
    users() {
      return this.$store.state.matches.users
    },
    currentMatch() {
      return this.$store.getters['matches/getMatchById'](this.selectedMatchId)
    },
    currentMatchOddsTables() {
      return this.currentMatch?.oddsTables || []
    },
    currentOddsId() {
      return this.currentMatch?.odds_id || 0
    },
    currentOdds() {
      return this.currentMatchOddsTables.find(t => t.publishTime === this.selectedOddsTime)
    },
    totalBetting() {
      return this.bettingAmounts.reduce((sum, amount) => sum + (amount || 0), 0)
    },
    tableDataSet() {
      if (this.currentOdds) {
        return [{"colCount": 3, "odds": this.currentOdds.had, "title": "胜负", "titleColor": "lightgrey"},
                {"colCount":8, "odds": this.currentOdds.crsh, "title": "主队胜", "titleColor": "lightblue"},
                {"colCount":6, "odds": this.currentOdds.crsd, "title": "平", "titleColor": "lightblue"},
                {"colCount":8, "odds": this.currentOdds.crsa, "title": "主队负", "titleColor": "lightblue"},
                {"colCount":4, "odds": this.currentOdds.ttg, "title": "总进球数", "titleColor": "lightgreen"},
                {"colCount":3, "odds": this.currentOdds.hafu, "title": "半全场胜平负", "titleColor": "lightpink"},
              ]
      }
      return []
    }
  },
  methods: {
    async fetchInitialData() {
      await this.$store.dispatch('matches/fetchMatches')
    },
    onCheckAllChange(e) {
      this.leagueSelectAll = e.target.checked;
      if (e.target.checked) {
        this.leagueSelected = this.leagues.map(league => league.leagueId);
      } else {
        this.leagueSelected = [];
      }
      localStorage.setItem('leagueSelected', JSON.stringify(this.leagueSelected));
      this.leagueSelectionIndeterminate = false;
    },
    onLeagueSelecttionChange(value) {
      const index = this.leagueSelected.indexOf(value);
      if (index > -1) {
        this.leagueSelected.splice(index, 1);
      } else {
        this.leagueSelected.push(value);
      }
      localStorage.setItem('leagueSelected', JSON.stringify(this.leagueSelected));
      this.leagueSelectAll = this.leagueSelected.length === this.leagues.length;
      this.leagueSelectionIndeterminate = this.leagueSelected.length > 0 && this.leagueSelected.length < this.leagues.length;
    },
    handleMatchChange() {
      if (this.currentMatch && this.currentMatchOddsTables.length) {
        this.selectedOddsTime = this.currentMatchOddsTables[this.currentMatchOddsTables.length - 1].publishTime
        this.selectedOddsItems = []
        this.bettingAmounts = []
      }
    },
    handleOddsChange() {
      this.selectedOddsItems = []
      this.bettingAmounts = []
    },
    applyMoneyPerOdd() {
      console.log(this.amountPerOdd, this.selectedOddsItems, this.bettingAmounts);
      for (let [index, odd] of this.selectedOddsItems.entries()) {
        this.bettingAmounts[index] = this.amountPerOdd;
      }
      this.calculateResults();
    },
    async getRecommendation(algoId) {
      const data = { userId: this.userId, oddsId: this.currentOddsId, algoId: algoId }
      try {
        const response = await axios.post('https://api.ip008.com/odds/recom.php', data)
        console.log(response.data)
        console.log(this.currentOdds.crsa)
        this.selectedOddsItems = response.data
        this.bettingAmounts = []
        for (let [index, odd] of this.selectedOddsItems.entries()) {
          this.bettingAmounts[index] = odd.amount;
        }
        this.calculateResults();
      } catch (error) {
        console.error('推荐失败:', error)
        alert('推荐失败')
      }
    },
    applyRecommendation1() {
      this.getRecommendation(1);
    },
    applyRecommendation2() {
      this.getRecommendation(2);
    },
    handleTableData(data) {
      // this.selectedData1 = data;
      this.selectedOddsItems = data;
      console.log(data)
    },
    handleTableSelectionChange(items) {
      this.selectedOddsItems = items;
      console.log("handleTableSelectionChange", items)
    },
    calculateReturn(odds, amount) {
      return ((odds * amount) || 0).toFixed(2)
    },
    calculateNetReturn(odds, amount) {
      return (((odds * amount) - this.totalBetting) || 0).toFixed(2)
    },
    calculateROI(odds, amount) {
      if (!amount) return '0.00'
      return (((odds * amount - this.totalBetting) / this.totalBetting) * 100).toFixed(2)
    },
    calculateResults() {
      this.bettingAmounts = [...this.bettingAmounts]
    },
    async saveBettingData() {
      const bettingData = this.selectedOddsItems.map((item, index) => ({
        odds: item.name,
        amount: this.bettingAmounts[index] || 0
      }))
      const userId = (Number(this.userId) == 1) ? this.selectedUserId : this.userId
      const data = { userId: userId, matchId: this.selectedMatchId, oddsId: this.currentOddsId, betting: bettingData }

      try {
        await axios.post('https://api.ip008.com/odds/save.php', data)
        alert('保存成功')
      } catch (error) {
        console.error('保存失败:', error)
        alert('保存失败')
      }
    }
  },
  created() {
    let selectedString = localStorage.getItem('leagueSelected');
    if (selectedString) {
      this.leagueSelected = JSON.parse(selectedString);
    } else {
      this.leagueSelected = [];
    }
    this.fetchInitialData()
  }
}
</script>

<style scoped>
.home-container {
  padding: 0;
  max-width: 1200px;
  margin: 0 auto;
}

.league-selection {
  margin-bottom: 20px;
}

.league-list {
  margin-top: 16px;
}

.league-list .ant-row {
  display: flex;
  flex-wrap: wrap;
  margin: -8px;
}

.league-list .ant-col {
  padding: 8px;
}

.league-list .ant-col:nth-child(-n+4) {
  flex: 0 0 25%;
  max-width: 25%;
}

.league-list .ant-col:nth-child(n+5) {
  flex: 0 0 50%;
  max-width: 50%;
}

.match-selection {
  margin-bottom: 20px;
  display: flex;
  gap: 20px;
}

select {
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #ddd;
  min-width: 200px;
}

.odds-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 10px;
  margin-bottom: 20px;
}

.odds-item {
  padding: 10px;
  border: 1px solid #eee;
  border-radius: 4px;
}

.action-area {
  margin-bottom: 10px;
}
.betting-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

.betting-table th,
.betting-table td {
  border: 1px solid #ddd;
  padding: 10px;
  text-align: center;
}

.betting-table th {
  background-color: #f5f5f5;
}

.betting-table input {
  width: 100px;
  padding: 5px;
  text-align: right;
}

.total-row {
  background-color: #f9f9f9;
  font-weight: bold;
}

.betting-table-section h3 {
  font-weight: bold;
  margin-top: 10px;
}

.save-button {
  padding: 10px 20px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-left: 20px;
}

.save-button:hover {
  background-color: #45a049;
}
</style>
