<template>
  <div 
    class="check-grid" 
    :class="{ 'selected': isSelected }"
    @click="toggleSelection"
  >
    <div class="key-row">{{ item.title }}</div>
    <div class="value-row">{{ item.value }}</div>
  </div>
</template>

<script>
export default {
  name: 'CheckGrid',
  props: {
    item: {
      type: Object,
      required: true,
      validator: obj => obj.hasOwnProperty('name') && obj.hasOwnProperty('title') && obj.hasOwnProperty('value')
    },
    selectedOddsItems: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  computed: {
    isSelected() {
      // return newSelectedItems.includes(this.item);
      return this.selectedIndex >= 0
    },
    selectedIndex() {
      // let newSelectedItems = [...this.selectedOddsItems];
      const index = this.selectedOddsItems.findIndex((i) => i.name === this.item.name);
      return index;
    }
  },
  methods: {
    toggleSelection() {
      console.log("grid toggle selection:", this.selectedOddsItems, this.item);
      let newSelectedItems = [...this.selectedOddsItems];
      const index = newSelectedItems.findIndex((i) => i.name === this.item.name);
      if (index > -1) {
        newSelectedItems.splice(index, 1);
      } else {
        newSelectedItems.push(this.item);
      }
      // 触发自定义事件，将修改后的数据传递给父组件
      console.log("grid toggle selection emit: ", newSelectedItems);
      this.$emit('selection-change', newSelectedItems);

      // this.selection = !this.isSelected;
      // this.$emit('selection-change', {
      //   item: this.item,
      //   selected: this.selection
      // });
    }
  }
}
</script>

<style scoped>
.check-grid {
  border: 1px solid #e8e8e8;
  cursor: pointer;
  transition: all 0.3s;
  height: 100%;
}

.key-row {
  background-color: #f0f0f0;
  padding: 2px;
  text-align: center;
  border-bottom: 1px solid #e8e8e8;
  font-weight: bold;
}

.value-row {
  padding: 2px;
  text-align: center;
}

.selected {
  background-color: #e6f7ff;
}
</style>