<template>
  <div class="home-container">
    <Header /> <!-- 引入顶部导航菜单组件 -->

    <a-space class="action-space" direction="vertical" :size="12">
      <a-select
        v-model:value="selectedUserId"
        v-if="userId == 1"
        style="width:100%"
        @change="handleUserChange"
        placeholder="选择用户"
      >
        <a-select-option 
          v-for="user in users" 
          :key="user.user_id" 
          :value="user.user_id"
        >
          {{ user.info }}
        </a-select-option>
      </a-select>

      <a-range-picker
        style="width:100%"
        :presets="rangePresets"
        v-model="dateRange"
        :default-value="defaultDateRange"
        @change="handleRangeChange" />

      <a-space class="spacing">
        筛选：
        <a-checkbox
          v-model:checked="leagueSelectAll"
          :indeterminate="leagueSelectionIndeterminate"
          @change="onCheckAllChange"
          >
          全选
        </a-checkbox>
        <div class="league-list">
          <a-space class="league-space">
            <a-checkbox v-for="league in leagues" :key="league.leagueId" :value="league.leagueId" :checked="leagueSelected.includes(league.leagueId)" @change="onLeagueSelecttionChange(league.leagueId)">{{ league.leagueName }}</a-checkbox>
          </a-space>
        </div>
      </a-space>

      <a-table :columns="columns" :data-source="filteredMatches" :rowKey="record => record.betting_id" bordered :pagination="false" class="spacing">
        <template #bodyCell="{ column, text }">
          <span v-if="column.dataIndex === 'index'">{{ text + 1 }}</span>
          <span v-else>{{ text }}</span>
        </template>
      </a-table>

      <a-row class="spacing" style="text-align:center;">
        <a-col :span="8">
          <a-statistic title="总投入" :value="totalAmount" style="margin-right: 50px" />
        </a-col>
        <a-col :span="8">
          <a-statistic title="总收入" :precision="2" :value="totalIncome" />
        </a-col>
        <a-col :span="8">
          <a-statistic title="收益率" suffix="%" :precision="2" :value="incomeRate" />
        </a-col>
      </a-row>

    </a-space>

  </div>
</template>

<script>
import { mapState } from 'vuex'
import axios from 'axios'
import moment from 'moment'
import CheckboxTable from '../components/CheckboxTable.vue'
import CheckTable from '../components/CheckTable.vue'
import Header from '../components/Header.vue'

export default {
  name: 'Stats',
  meta: {
    title: '足彩助手 - 统计'
  },
  components: {
    Header,
    CheckTable,
    // 'a-button': Button,
    CheckboxTable
  },
  data() {
    const sevenDaysAgo = moment().subtract(7, 'days');
    const today = moment();
    return {
      selectedUserId: '',
      dateRange: [sevenDaysAgo, today],
      defaultDateRange: [sevenDaysAgo, today],
      rangePresets: [
        {
          label: '7天',
          value: [moment().subtract(7, 'days'), moment()]
        },
        {
          label: '14天',
          value: [moment().subtract(14, 'days'), moment()]
        },
        {
          label: '一个月',
          value: [moment().subtract(1, 'months'), moment()]
        }
      ],
      leagueSelectAll: false,
      leagueSelectionIndeterminate: false,
      leagueSelected: [],
      columns: [
        {
          title: '序号',
          dataIndex: 'index',
          key: 'index'
        },
        {
          title: '比赛',
          dataIndex: 'game',
          key: 'game'
        },
        {
          title: '投入',
          dataIndex: 'total_amount',
          key: 'total_amount'
        },
        {
          title: '收入',
          dataIndex: 'income',
          key: 'income'
        }
      ]
    }
  },
  computed: {
    userId() {
      return localStorage.getItem('userId')
    },
    users() {
      return this.$store.state.stats.users
    },
    matches() {
      return this.$store.state.stats.matches
    },
    leagues() {
      return this.$store.state.stats.leagues
    },
    filteredMatches() {
      return this.matches
        .filter(match => this.leagueSelected.includes(match.leagueId))
        .map((match, index) => ({ ...match, index: index + 1 }));
    },
    totalAmount() {
      let sum = 0;
      // 遍历数组并累加 total_amount 的值
      this.filteredMatches.forEach(item => {
        sum += Number(item.total_amount);
      });
      return sum;
    },
    totalIncome() {
      let sum = 0;
      // 遍历数组并累加 income 的值
      this.filteredMatches.forEach(item => {
        sum += Number(item.income);
      });
      return sum;
    },
    incomeRate() {
      return this.totalAmount > 0 ? (this.totalIncome - this.totalAmount) * 100 / this.totalAmount : 0;
    }
  },
  methods: {
    async fetchUserData() {
      const startDate = this.dateRange[0].format('YYYY-MM-DD');
      const endDate = this.dateRange[1].format('YYYY-MM-DD');
      const params = {
          user_id: this.userId,
          show_user_id: this.selectedUserId,
          start_date: startDate,
          end_date: endDate
        }
      await this.$store.dispatch('stats/fetchBettingStats', params);
      this.selectAllLeagues();
    },
    fetchInitialData() {
      console.log("Fetching initial data for Stats. userId: ", this.userId)
      console.log("dates: ", this.dateRange, "default dates: ", this.defaultDateRange)
      this.selectedUserId = this.userId;
      this.fetchUserData()
    },
    handleUserChange() {
      console.log("User changed", this.selectedUserId)
      this.selectedMatchId = ''
      this.fetchUserData()
    },
    onRangeChange(dates, dateStrings) {
      console.log("onRangeChange", dates, dateStrings)
      console.log("current: ", this.dateRange)
    },
    handleRangeChange(dates) {
      console.log('用户选择的日期范围:', dates.map(date => date.format('YYYY-MM-DD')));
      this.dateRange = dates;
      this.fetchUserData(this.selectedUserId)
    },
    onCheckAllChange(e) {
      this.leagueSelectAll = e.target.checked;
      if (e.target.checked) {
        this.leagueSelected = this.leagues.map(league => league.leagueId);
      } else {
        this.leagueSelected = [];
      }
      localStorage.setItem('leagueSelected', JSON.stringify(this.leagueSelected));
      this.leagueSelectionIndeterminate = false;
    },
    onLeagueSelecttionChange(value) {
      const index = this.leagueSelected.indexOf(value);
      if (index > -1) {
        this.leagueSelected.splice(index, 1);
      } else {
        this.leagueSelected.push(value);
      }
      localStorage.setItem('leagueSelected', JSON.stringify(this.leagueSelected));
      this.leagueSelectAll = this.leagueSelected.length === this.leagues.length;
      this.leagueSelectionIndeterminate = this.leagueSelected.length > 0 && this.leagueSelected.length < this.leagues.length;
    },
    selectAllLeagues() {
      this.leagueSelectAll = true;
      this.leagueSelected = this.leagues.map(league => league.leagueId);
      localStorage.setItem('leagueSelected', JSON.stringify(this.leagueSelected));
      this.leagueSelectionIndeterminate = false;
    }
  },
  created() {
    this.fetchInitialData()
  }
}
</script>

<style scoped>
.home-container {
  padding: 0;
  max-width: 1200px;
  margin: 0 auto;
}

.action-space {
  margin-top: 10px;
}

.match-selection {
  margin-bottom: 20px;
  display: flex;
  gap: 20px;
}

select {
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #ddd;
  min-width: 200px;
}

.spacing {
  margin: 10px auto;
}
</style>
