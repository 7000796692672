import Vue from 'vue'
import Vuex from 'vuex'
import matches from './modules/matches'
import bettings from './modules/bettings'
import settings from './modules/settings'
import stats from './modules/stats'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    matches,
    bettings,
    stats,
    settings
  }
})
